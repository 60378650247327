<!--
 * @Author       : JiangChao
 * @Date         : 2022-11-28 21:40:42
 * @LastEditors  : JiangChao
 * @LastEditTime : 2024-04-15 10:10:43
 * @Description  : 協議與隱私規定
-->
<template>
	<div class="container">
		<div class="f26">
        <div>一、服務內容<br/>
            旅電共享行動電源是由旅電科技股份有限公司（以下簡稱「本公司」）所建置及提供之行動電源租借服務（以下簡稱「本服務」）。本使用協議（以下簡稱「本協議」）構成本公司與本服務使用者（以下簡稱「用戶」）間具有法律拘束力之契約關係。於用戶開始使用本服務時，即視為用戶已閱讀、暸解、並同意接受本協議之所有內容。<br/>
        </div>
        <div class="item">
二、認知與接受協議<br/>
            1. 本公司有權隨時修訂本協議之內容，修訂後之本協議會透過本服務公告，以供用戶瞭解。<br/>
2. 本協議經修訂後之內容，除另有說明者外，自公告時起生效。自生效日起，如用戶繼續使用本服務，即視為用戶已閱讀、瞭解、並同意修訂後協議之所有內容。如用戶不同意修改後之內容，用戶應停止使用本服務，且本公司得終止與用戶間之合約，並不因此而對用戶負擔任何賠償或補償之責任。<br/>
3. 本公司有權不定時針對本服務之全部或一部公告本協議以外之補充條款（包括但不限於：對於特定事件之政策、優惠券使用等）。補充協議自公告時起生效，並構成本協議之一部，對用戶具有拘束效力。<br/>
            三、註冊帳號義務<br/>
            1. 註冊帳號<br/>
            為了使用本服務，用戶必須註冊並維護個人用戶服務帳號。本服務將提供個人資料頁面，以便更新用戶的個人資訊，例如姓名、電子郵件和手機號碼。用戶同意在帳號中保留準確、完整和最新的資訊。如用戶未能保持準確、完整和最新的帳號資訊，這可能導致用戶無法使用本服務。用戶對帳號下發生的所有活動負責，並且同意始終保持用戶帳號和密碼的安全性和保密性。除非本公司書面許可，用戶只能擁有一個帳戶。<br/>
            2. 用戶使用要求<br/>
            任一用戶就使用本服務所取得之帳號，僅供用戶本人使用，該帳號不可轉讓、贈與或繼承，且用戶不得以有償或無償之方式透露或提供予任何第三人登錄或使用。任何經由輸入該組帳號所發生的交易、活動，均視為註冊該帳號之用戶之行為，並應由註冊該帳號之用戶自負其責。用戶如果發現或懷疑其帳號遭第三人冒用或不當使用，應立即通知本公司，以便本公司採取適當之因應措施。但上述因應措施不得解釋為本公司因此而明示或默示對用戶負有任何形式之賠償或補償義務。用戶瞭解並同意，本公司得藉由簡訊、電子郵件、電話等方式提供用戶關於本公司、本服務及合作商家之相關資訊。用戶瞭解並同意，不論本公司因何種原因註銷用戶帳號或終止提供本服務，本公司就用戶帳號之所有相關資訊及紀錄均不負擔備份之義務<br/>
           3. 簡訊<br/>
            通過創立帳號，用戶同意本服務可能會向用戶發送簡訊認證（SMS），作為使用本服務的正常業務操作的一部分。如果用戶對從本公司接收簡訊（SMS）有疑問，可以通過pluginn.info@gmail.com與本公司聯繫。用戶確認選擇不接收簡訊（SMS），可能會影響用戶對本服務的使用。<br/>
           4. 個人資料<br/>
            (1). 個人資料蒐集類別依「個人資料保護法之特定目的及個人資料之類別」規定，用戶為使用本服務提供本公司之個人資料類別包括：姓名、行動電話、電子郵件地址、信用卡號碼及電子票證號碼。個人資料如有登錄不實、不完整或錯誤者，本公司有權暫停或終止用戶使用本服務之全部或一部分。<br/>
            (2). 個人資料利用期限依相關法令所定（例如商業會計法等）或因執行業務所必須之保存期間或依個別契約就資料之保存所定之保存年限（以期限最長者為準）。<br/>
            (3). 個人資料使用目的依「個人資料保護法之特定目的及個人資料之類別」規定，用戶的個人資料將使用目的包括：(069)契約、類似契約或其他法律關係事務、(063)非公務機關依法定義務所進行個人資料之蒐集處理及利用、(090)消費者、客戶管理與服務、(135) 資（通）訊服務、(136)資（通）訊與資料庫管理、(137)資通安全與管理、(143)運動休閒業務、(157)調查、統計與研究分析、(180) 其他經營公共事業業務、 (181)其他經營合於營業登記項目或組織章程所定之業務。用戶了解當使用本服務時，依上述規定提供本公司之個人資料，將依上述規定之使用目的範圍內，供本公司、主管機關、關係企業及合作夥伴內部使用。<br/>
        </div>
        <div class="item">
            四、使用服務範圍規範<br/>
本服務是為用戶提供行動電源租借服務。用戶得透過由本公司營運之旅電應用程式及（或）其他與本公司合作之協力廠商所營運之應用程式，以搜尋用戶所在地附近之充電站，並於充電站透過掃描QR Code或其他方式完成行動電源之租借。除經本公司另行書面同意，本服務僅供用戶個人之非商業用途使用。<br/>
1.	支付<br/>
            用戶瞭解並同意，用戶得透過信用卡支付、行動支付等付款方式向本公司給付行動電源租借費用，惟前述付款方式可能存在一定之風險，包括但不限於：不法份子利用用戶的帳戶或信用卡等支付工具進行違法活動、因非可歸責於本公司之事由致款項無法收取，該等風險可能會給用戶造成相應之損害，此損害將由用戶自行向侵權方追究相關之侵權與賠償責任。支付功能本身並不收集用戶個人資訊，但本公司需要將用戶儲值紀錄及對帳資訊與支付連結以確認用戶的支付指令完成並支付。用戶瞭解並同意，用戶於本服務中執行之操作均由本服務之系統即時接收與處理。除本服務明確提供撤回、取消或修改該用戶指示之功能外，一旦用戶確認交易，即不得撤回、取消或修改已發送至本服務系統之交易指示。<br/>
            2. 付款<br/>
            (1). 用戶了解使用本服務將可能會向用戶收取相關的服務費用。當用戶使用本服務後，將支付因使用服務所衍生的費用。在法律規定下，收費將包括適用的稅費。<br/>
            (2). 支付方式在首次使用需先支付保證金或綁定支付方式，作為註冊過程的一部分，保證金金額為新台幣420元。<br/>
            (3). 保證金在結束訂單或是沒有未結帳訂單情況下可退還；處理退款時間需要1至14個工作天。<br/>
            (4). 用戶若使用支付保證金方式，將於訂單結束時生成一筆訂單請求付款，用戶可使用上述各種支付方式付款即可再次租借或是請求退回保證金。用戶若使用綁定方式，將於訂單結束自動於綁定方式進行付款。<br/>
            (5). 行動電源租借費用為每小時新台幣15元，單日費用上限是新台幣60元、超過24小時依上述規範累積計價，惟部份合作場域費用可能有不同設定，用戶使用時可於旅電應用程式查看，用戶使用本服務後即代表同意該場域每小時費用。用戶七天未歸還行動電源，本公司將有權直接扣除連續使用七天之費用，行動電源將歸用戶所有。<br/>
            (6). 用戶瞭解並同意本公司可能會不定時向特定用戶提供促銷優惠與折扣，因此可能產生用戶使用相同或類似服務（或商品）時所支付之金額不一致情形，用戶對此並無異議。如用戶使用本服務遇有退款情形，本公司處理退款之作業時間為1至14個工作天，且所有退還款項將返還至用戶於本服務之帳戶之中（即用戶之「我的錢包」帳戶）。<br/>
3. 優惠券<br/>
本公司有權不定時發起優惠活動，向符合特定條件之用戶發放優惠券、點數或其他與本服務相關的優惠代碼（以下合稱「優惠券」），惟優惠券之發放條件、使用規則及其他一切相關事項均應適用下列規定及其他本公司所公告之優惠券使用規則（以下簡稱「優惠券使用條款」）：<br/>
(1). 優惠券僅適用於本公司同意發放之對象使用。除經本公司明確許可，用戶不得以任何方式複製、出售、轉讓，或向公眾提供之；<br/>
(2). 用戶應依優惠券使用條款所載之使用方式使用優惠券（包括但不限於：使用時段、使用限制等）；<br/>
(3). 本公司可能於任何時間、因任何理由向用戶收回已發放的優惠券，而本公司對此並不承擔任何賠償或補償責任；<br/>
(4). 若用戶經本公司認定有不當使用優惠券之情形者，本公司有權向用戶追回相當於優惠券優惠數額之款項。如因此致損害於本公司者，本公司另得向用戶請求損害賠償；<br/>
(5). 不論優惠券使用條款是否訂有發放期限，本公司有權隨時終止發放優惠券之活動；<br/>
(6). 用戶瞭解並同意，如優惠券使用條款與本協議之規範有所衝突，或有未盡事宜者，本公司享有最終之解釋權與決定權。<br/>
(7). 本公司有權隨時變更、限制本服務內容，以及終止全部或一部之本服務內容，而無需另行事先通知用戶，且本公司不因此承擔任何損害賠償責任。<br/>
        </div>
        <div class="item">
            五、系統運作<br/>
            當行動電源解鎖時，或者當用戶用旅電應用程式解鎖行動電源時，用戶租借行動電源的時間就會開始進行計時。當行動電源被歸還時，租借期間即結束。如果行動電源在租借期後並未被退回及鎖定在本公司設備，本公司將收取額外費用。<br/>
        </div>
        <div class="item">
            六、維護服務<br/>
            本公司負責行動電源的日常運行、維護和維修工作，但這並不意味著公司有義務確保所有可用的行動電源任何時候均處於無故障狀態。用戶應該在使用行動電源之前確認部件的完整性、效率，並熟悉行動電源的性能。如果用戶發現行動電源無法正常使用，請透過本公司客服方式通知相關問題。如果用戶無視損壞的行動電源並繼續使用，用戶將對該行為及有關後果負責。<br/>
        </div>
        <div class="item">
            七、所有權<br/>
            本服務及其所有權利均為本公司的財產或本公司許可人的財產。 本協議或用戶對服務的使用都不會向用戶轉讓或授予您任何權利。在本服務中或與服務有關的服務，除了上述授予的有限許可外，皆不可以任何方式使用或引用本公司的公司名稱、標誌、產品和服務名稱、商標或服務標誌、或本公司許可人的標誌。<br/>
            </div>
        <div class="item">
八、行動電源使用限制及其他條款<br/>
            1. 陳述和保證<br/>
            用戶承諾，於開始使用本服務前，已詳閱本協議並熟悉與行動電源安全及合法操作的相關及所有適用法規<br/>
2.	承諾和協議<br/>
            在本公司允許用戶使用本服務前，用戶代表並保證向本公司承諾：<br/>
           (1). 用戶充分意識到在使用行動電源時不能在危險情況下使用，如使用時不能與水接觸、不能把行動電源接近火種、不能把行動電源折開等，用戶必須保持適當的警惕以避免此類事故。<br/>
           (2). 用戶全權負責以謹慎合理的方式操作行動電源。<br/>
           (3). 所有行動電源始終為本公司的專屬財產。<br/>
           (4). 本公司無義務提供用戶使用行動電源服務有關的任何種類的保險，如果本公司有購買相關保險，用戶仍然對任何責任、財產損失、人身傷害、損害等作出賠償並負責所有罰款、損失和/或任何形式的費用。<br/>
           (5). 如果用戶在操作或保管行動電源時造成財產損失或對另一方造成的傷害，用戶對此類損害或傷害負全部責任。<br/>
           (6). 用戶應完好無缺地將行動電源歸還本公司設備。<br/>
           (7). 用戶對由於不正當使用或濫用行動電源造成的任何和所有損害以及損害賠償的費用承擔責任。<br/>
           (8). 本公司為租借行動電源提供方便，此類租借只能由已同意本協議的所有條款和條件的用戶使用。<br/>
           3. 要求<br/>
           在本公司允許用戶使用服務並租借行動電源前，用戶在任何使用行動電源（“使用要求”）期間應執行以下操作：<br/>
            (1). 在使用前仔細檢查用戶望租借的行動電源，以確保行動電源的運行狀況良好。<br/>
            (2). 在進行預期用途之前測試行動電源的操作部件，包括但不限於電池和充電線。<br/>
            (3). 於使用期間，如用戶發現行動電源的任何缺陷、故障或毀損時，應儘快聯繫本公司，以便本公司進行後續維修。如用戶察覺行動電源有任何缺陷、故障、毀損情形而予以無視並繼續使用，用戶將對該行為及有關後果擔負全責（包括但不限於：因此而造成行動電源之缺陷或毀損情形擴大）； 於使用期間，如行動電源有遭竊或造成任何人身傷害事故之情形發生者，用戶應立即聯繫本公司及當地警務單位；<br/>
            (4). 本公司負責行動電源的日常運行、維護和維修工作，但這並不代表本公司有義務擔保所有可用的行動電源於任何時候均處於無故障狀態。用戶應該於使用行動電源之前按照本條款規定確認其配件及效率的完整性，並熟悉行動電源的性能。<br/>
        </div>
        <div class="item">
            九、賠償<br/>
            用戶違反本協議和所有與本協議相關或因此產生的聲明時，包括但不限於用戶違反本協議中規定的任何聲明、保證或協議，均須為本公司提供賠償和保護本公司對行動電源的租借、維護、設計、使用、操作或公司網站，即使是由本公司的疏忽造成的全部或部分損失或其他人的疏忽，或是目前已知的還是未知的損害。根據本公司的意願，用戶將負責任何索賠的辯護和解決方案，並由用戶補償（前提是在此情況下，本公司可隨時選擇接管控制任何此類索賠的辯護和解決方案）。在任何情況下，用戶均不得在取得本公司書面同意的情況前解決任何索賠情況。<br/>
            </div>
        <div class="item">
十、用戶行為<br/>
1. 用戶於本服務所提供之資料（包括但不限於用戶之手機號碼、大頭貼照片、電子信箱等） （以下簡稱「提交內容」），本公司並未事先過濾或審查其內容，對於其合法性，本公司不負任何明示或默示之承諾或擔保，均由用戶自負其責。<br/>
2. 用戶提交內容如有違反法令、違背公序良俗、侵害第三人權益、或違反本條款之虞者，為維護本公司及第三人之權益、或避免損害或爭議擴大，本公司得不經事先通知，直接加以移除，並得暫停或終止該用戶使用本服務。<br/>
3. 當本公司合理懷疑用戶有不法行為時，用戶同意本公司得先行鎖定用戶的帳號，並且主動或配合相關單位，移送用戶的相關資料供檢警調查處理，並靜待終局司法判決。<br/>
4. 用戶了解並同意，本公司依據法律的要求，或基於以下目的之合理必要範圍內，認定必須將用戶的帳號資訊、提交內容、用戶交易資料加以保存或揭露予政府機關、司法警察或未成年人之監護人時，得加以保存及揭露：<br/>
(1). 遵守法令或政府機關之要求；<br/>
(2). 為提供本服務所必須者；<br/>
(3). 為防止他人權益之重大危害而有必要者；<br/>
(4). 為免除用戶及公眾之生命、身體、自由、權利、財產上之急迫危險者。<br/>
5. 若因可歸責於用戶之行為所導致的任何損失，均與本公司無關，該可歸責行為包括但不限於：未按照系統提示操作本服務、洩漏登入本服務之驗證碼、用戶使用之手機遭他人侵入等。<br/>
6. 用戶承諾絕不為任何非法目的或以任何非法方式使用本服務，並承諾遵守適用法律及一切使用網際網路之國際慣例。<br/>
7. 用戶不得利用本服務從事侵害本公司或他人權益之行為，否則本公司有權拒絕提供本服務，且用戶應承擔所有相關法律責任。如因用戶之不法行為，導致本公司、本公司僱員、其他用戶或本服務協力廠商受有損害者，用戶並應承擔損害賠償責任。用戶之不當行為包括但不限於：<br/>
(1). 冒用他人帳戶使用本服務；<br/>
(2). 自本服務的任何部分刪除任何版權、商標或其他所有權聲明；<br/>
(3). 以其他方式取得本服務數據的任何部分，或者開啟使本服務過度負擔的程式，或妨礙本服務任何方面的操作及（或）功能；<br/>
(4). 以任何方式干擾或中斷本服務或伺服器或連結本服務之網路；<br/>
(5). 從事任何可能含有病毒或可能侵害本服務系統、資料之行為；<br/>
(6). 試圖獲得未經本公司授權的造訪；<br/>
(7). 其他本公司有正當理由認為不適當之行為。<br/>
8. 用戶有責任自行獲取使用本服務所需的網路數據。如用戶自無線設備造訪或使用本服務，用戶的行動上網費用、消息傳送速率由用戶自行承擔。用戶並應負責獲取和更新使用旅電應用程式及其任何更新所需的相容硬體或設備。本公司不保證本服務或其任何部分將在任何特定的硬體或設備上成功運作。此外，本服務亦可能會因使用網際網路和電子通訊而發生故障和延誤。<br/>
        </div>
        <div class="item">
            十一、廣告<br/>
            用戶在本服務中瀏覽到的所有廣告內容、文字與圖片之說明、展示樣品或其他銷售資訊，用戶因信任該廣告而受有損害者，除本公司明知或可得而知該廣告有不實之情事而仍為刊登者外，本公司不負任何賠償責任。<br/>
             </div>
        <div class="item">
十二、協力廠商<br/>
1. 用戶瞭解並同意，本服務內含非本公司或本公司關係企業之協力廠商廣告及連結。用戶使用協力廠商之任何商品或服務，其權利義務關係僅存在於用戶與該協力廠商之間。本公司僅提供行動電源租借服務，就用戶與協力廠商間所生之任何糾紛，應由用戶與第三方業者自行協調解決。本公司就協力廠商提供之商品或服務並不負任何明示或默示之承諾或擔保責任。<br/>
2. 本公司可通過本服務內提供之協力廠商廣告、行銷內容及其他機制（以下合稱「協力廠商廣告」）來補貼服務及（或）獲得額外收入。透過同意本條款，用戶同意接受協力廠商廣告。如用戶不同意收到協力廠商廣告，用戶應以書面或按照旅電應用程式內指示方式通知本公司。如用戶選擇不接收協力廠商廣告，本公司有權向用戶收取額外費用，或得拒絕用戶使用本服務。<br/>
3. 用戶瞭解並同意，協力廠商就其所提供之產品或服務可能另有適用之服務條款及政策。本公司對此類協力廠商之產品、服務和內容不負審核義務，且在任何情況下，本公司對此類協力廠商服務提供之任何產品、服務和內容將不承擔任何責任。此外，如用戶使用針對Apple iOS、Android行動設備開發的應用程式造訪本服務，Apple Inc.、Google Inc.及（或）其適用之關係企業並非本協議締約當事方。用戶使用這些設備以造訪本服務，將受到上述協力廠商之服務條款所約束。<br/>
</div>
        <div class="item">
十三、智慧財產權<br/>
1. 本服務所使用之旅電應用程式、本公司網站所有內容，包括但不限於著作、圖片、檔案、資訊、資料、程式架構、應用程式或網站畫面的安排、網站架構、頁面設計等，均由本公司或其他權利人依法擁有其智慧財產權，包括但不限於商標權、專利權、著作權、營業秘密與專有技術等。用戶不得逕自使用、修改、重製、公開播送、改作、散布、發行、公開發表、進行還原工程、解編或反向組譯。若用戶欲引用或轉載前述軟體、程式或網站內容，必須依法取得本公司或其他權利人的事前書面同意。如有違反，用戶應對本公司負損害賠償責任（包括但不限於訴訟費用及律師費用等）。<br/>
2. 本條款之存在並無向用戶轉讓、或授予用戶以任何方式使用或引用本公司的公司名稱、標誌、產品和服務名稱、商標或服務標誌之權利。<br/>
3. 對於用戶提供到本服務之提交內容，本公司並不聲明擁有所有權。然而，用戶在張貼、上傳、輸入、提供或提交該內容時，即表示永久且不可撤回的授予本公司得基於行銷及商業目的使用之，包含但不限於對於用戶的提交內容進行重製、散布、傳輸、公開展示、公開演出、編輯、翻譯等，以及公開與用戶的名字及（或）暱稱相互連結。<br/>
4. 用戶在張貼、上傳、輸入、提供或提交內容時，應擔保其擁有所有提交該內容之權利，包括但不限於所有用戶有權提供、張貼、上傳、輸入或提交該內容所需之權利。否則若因此遭第三方主張侵害其權利者，本公司除得隨時移除提交內容外，如因此導致本公司受有損害者，用戶應向本公司負損害賠償責任（包括但不限於訴訟費用及律師費用等）。<br/>
         </div>
        <div class="item">
            十四、用戶隱私權政策<br/>
            當用戶使用本服務時，用戶會向本公司提供相關的個人資料或其他資料。本公司於蒐集個人資料或其他資料時，將依個人資料保護法及相關法令之規定為之，並僅於提供本服務之目的範圍內使用。當用戶瀏覽或使用本公司所提供之網站、網域、服務、旅電應用程式時，即表示用戶已閱讀及瞭解本隱私權政策所載事項，並同意本公司依本隱私權政策蒐集、處理及利用用戶的個人資料。<br/>
            本隱私權政策適用於所有本公司基於提供本服務之事由所蒐集的個人資料，包括：用戶使用本服務時向本公司提供的資訊；本公司於用戶使用本服務時所蒐集的資訊（例如：用戶於本服務中所訪問的頁面、用戶所使用的裝置及瀏覽器相關資訊、用戶的定位位置等）。<br/>
1.	個人資料蒐集目的<br/>
依據法務部頒佈之「個人資料保護法之特定目的及個人資料之類別」，本服務蒐集用戶個人資料之目的分類列示如下（日後代碼及項目名稱如有變更，下列目的亦隨同變更）：<br/>
(1). ○四○ 行銷<br/>
(2). ○六九 契約、類似契約或其他法律關係事務<br/>
(3). ○九○ 消費者、客戶管理與服務<br/>
(4). ○九八 商業與技術資訊<br/>
(5). 一四八 網路購物及其他電子商務服務<br/>
(6). 一五二 商業或廣告行為管理<br/>
(7). 一五七 調查、統計與研究分析<br/>
2. 個人資料蒐集類別<br/>
本公司於本服務中蒐集之個人資料類別列示如下：<br/>
(1). Ｃ○○一 辨識個人者：如姓名、職稱、住址、住家電話號碼、行動電話、通訊及戶籍地址、電子郵遞地址等資訊；<br/>
(2). Ｃ○○二 辨識財務者：如信用卡或金融機構帳戶資訊；<br/>
(3). Ｃ○○三 政府資料中之辨識者：身分證統一編號、護照號碼等資訊；<br/>
(4). Ｃ○一一 個人描述：年齡、性別、出生年月日、國籍等資訊；<br/>
(5). Ｃ○一二 身體描述：身高、體重、血型等資訊；<br/>
(6). Ｃ○三五 休閒活動及興趣：嗜好、運動及其他興趣等資訊。<br/>
3. 個人資料的蒐集、處理及利用方式<br/>
(1). 本公司所取得的個人資料僅供本公司於提供本服務之目的範圍內處理與利用。除經本公司事先說明、或依照相關法律規定所必須，否則本公司不會將用戶之個人資料提供給第三人、或作為提供本服務外之其他目的使用。<br/>
(2). 本服務向用戶蒐集之資料內容，包括但不限於：<br/>
· 個人資訊：個人資訊是指由用戶向本公司提供的資訊。即當用戶於使用本公司的服務、獲取訂閱、完成調查、在應用程式註冊、上傳內容、參與活動時所提供之資料。個人資訊是可以單獨識別用戶的任何資訊，其中包括但不限於用戶的姓名、出生年月日、電子郵件地址、電話號碼、聯絡地址、信用卡、帳單和聯繫資訊。<br/>
· 非個人資訊：非個人資訊是指無法識別用戶的資訊，但可能包括有關用戶使用本服務的資訊，包括但不限於用戶的位置、特徵、使用設備資訊。
· 當用戶使用本服務時，伺服器自行產生的相關記錄，包括但不限於用戶使用連線設備的IP位址、使用的瀏覽器、使用時間、瀏覽及點選資料紀錄⋯等。<br/>
(3). 個人資料之利用<br/>
一般情況下，用戶可以瀏覽旅電應用程式，而無需向本公司提交用戶之個人資料。但為使用戶完整使用本服務，本公司仍可能要求用戶提供個人資料。本公司會將用戶的個人資料使於與本服務相關之業務和活動，包括但不限於下列目的：<br/>
· 履行本公司與用戶簽訂的任何契約的義務；<br/>
· 根據本服務使用協議向用戶提供任何服務；<br/>
· 處理用戶參與任何活動、優惠活動、研究、比賽、意見調查、或任何作品，並與用戶進行溝通；<br/>
· 根據本服務使用協議處理、管理或驗證用戶的服務申請；<br/>
· 根據本服務使用協議驗證及（或）處理付款；<br/>
· 根據本服務使用協議開發、增強和提供所需要的服務內容，以滿足用戶的需求；<br/>
· 根據本服務使用協議處理任何退款或收費；<br/>
· 根據本服務使用協議可能需要進行的任何檢查；<br/>
· 回答用戶的問題、意見和反饋；<br/>
· 用於本公司內部管理目的，如審計、數據分析、資料庫記錄；<br/>
· 為偵查、預防和起訴犯罪目的；<br/>
· 為履行法律規定的義務；<br/>
· 發送行銷宣傳資料、特別優惠、節日問候簡訊；<br/>
· 通知並邀請用戶參加本公司、其合作夥伴、廣告商及（或）贊助商舉辦的活動或活動；<br/>
· 在本公司的子公司、關係企業及（或）集團控股公司間分享用戶的個人資料；<br/>
· 供協力廠商如供應商、開發商、廣告商、合作夥伴、活動公司或贊助商，與用戶進行溝通。<br/>
4. 個人資料之保護<br/>
(1). 本服務主機均設有防火牆、防毒系統等相關的各項資訊安全設備及必要的安全防護措施，以採用嚴格的措施保護用戶的個人資料。僅經過授權的人員始能接觸用戶的個人資料，且相關處理人員皆與本公司簽有保密約定。<br/>
(2). 如因業務需要而有必要委託其他單位提供本服務時，本公司亦會嚴格要求其遵守保密義務，並且採取必要檢查程序以盡力確保其將確實遵守。<br/>
5. Cookie之使用<br/>
本公司使用Cookie技術以儲存和檢視數據。Cookie能儲存用戶的瀏覽資訊，提供用戶更流暢及更優質的服務體驗，用戶有權決定接受或拒絕之。但是請注意，若用戶拒絕使用Cookie，本公司所提供的部分服務和特定功能可能無法正常使用。<br/>
6. 個人資料利用期間<br/>
在以下期間，本公司將保存、處理及利用用戶的個人資料：<br/>
(1). 於用戶持續使用本服務之期間；<br/>
(2). 為實現本隱私權政策蒐集用戶個人資料之目的所需之期間；<br/>
(3). 為遵守相關法令規範所要求之期間。<br/>
7. 網站對外的相關連結<br/>
請注意，本隱私權政策中描述的條款適用於本公司透過旅電應用程式、本公司網站或其他客戶服務人員在程式內蒐集的資訊。本服務中可能包含導向第三方網站及應用程式之連結。本公司對第三方網站及應用程式之行為並不承擔任何責任，本政策亦不適用於任何第三方網站及應用程式。無論該第三方網站及應用程式之服務提供者是否為本公司之關聯公司或合作對象，亦不論該第三方網站及應用程式之標示或連結是否顯現於本服務之任何介面之中，均同。在用戶訪問或將個人資訊提供給第三方網站及應用程式前，請參考該網站及應用程式的隱私權保護政策，並自行採取措施來保護用戶的隱私權及個人資料。<br/>
8. 個人資料分享與揭露<br/>
本公司絕不會提供、交換、出租或出售任何用戶的個人資料給其他個人、團體、私人企業或公務機關，但有法律依據或合約義務者，不在此限。前項但書之情形包括不限於：<br/>
(1). 事先獲得用戶明確授權者；<br/>
(2). 依法令規定、或依司法機關或其他相關政府機關之命令或要求；<br/>
(3). 為免除用戶生命、身體、自由或財產上之危險；<br/>
(4). 與公務機關或學術研究機構合作，基於公共利益為統計或學術研究而有必要，且資料經過提供者處理或蒐集著依其揭露方式無從識別特定之當事人者；<br/>
(5). 當用戶在網站的行為，違反本服務使用協議，或可能損害或妨礙本公司與本服務其他使用者權益、或導致任何人遭受損害時，經本公司認定揭露用戶的個人資料是為了辨識、聯絡或採取法律行動所必要者；<br/>
(6). 為保護其他用戶或其他第三人的合法權益；<br/>
(7). 為維護本公司及其關聯公司的合法權益。<br/>
為了提升本公司現有服務或為未來提供其他服務，本公司可能引進第三方業者之參與。本公司會向第三方業者及與關聯公司分享用戶的個人資料。本服務如委託第三方業者協助蒐集、處理或利用用戶的個人資料時，將對第三方業者善盡監督管理之責。<br/>
9. 個人資料之權利行使<br/>
用戶有權在本公司確認該個人資料為用戶本人所有後，透過本公司服務信箱行使以下權利：<br/>
(1). 請求查詢或閱覽用戶的個人資料；<br/>
(2). 請求提供用戶個人資料的複製本；<br/>
(3). 請求補充或更正用戶的個人資料；<br/>
(4). 請求停止蒐集、處理或利用用戶的個人資料；<br/>
(5). 請求刪除用戶的個人資料。<br/>
10. 隱私權保護政策之修正<br/>
通過使用旅電應用程式，用戶接受本隱私權政策中所描述的全部條款。如果用戶不同意本隱私權政策的條款，請不要使用本應用程式。本公司保留隨時修改本隱私權政策條款的權利，且修改後之本隱私權政策條款於公告時起生效，恕不另行通知。當本公司於旅電應用程式內發布本隱私權政策之更新條款後，如用戶繼續使用本服務，視為用戶已閱讀、瞭解並同意這些更改。<br/>
</div>
        <div class="item">
            十五、終止協議<br/>
            本公司可隨時無原因地終止本協議及相關法律程序，或者向用戶於使用本系統時作出通知。用戶放棄與任何此類合約終止相關的所有索賠、引起訴訟的費用或損害賠償。如果本協議由於特定原因而被終止，用戶將無權退還未使用的租借期間支付的任何金額。用戶可隨時終止用戶的租借服務。然而，如果用戶已使用服務，將不獲退回已於租借期間所使用的款項。<br/>
        </div>
        <div class="item">
            十六、殘存條款<br/>
            本協議有關限制和排除責任、放棄、承擔風險、擔保和賠償責任的所有條款應在本協議終止後仍然有效，並且在本協議終止或到期時未支付的所有款項應依然被視為應付款項。<br/>
        </div>
        <div class="item">
            十七、免責聲明<br/>
            1. 本服務係提供有行動電源租借服務，如因不可抗力之因素 (含天災及電信業者因素) 及系統必要之維修保養而暫時中斷服務者，對於因使用(或無法使用) 本服務而造成的損害，本公司不負任何賠償責任。<br/>
            2. 本服務之部分內容將須透過網路連接進行。本服務對於技術故障、斷電或任何非得由本公司控制之因素所造成之網路連接異常，均不負任何賠償責任。本公司對於任何用戶因使用本服務有關的直接、特殊、附隨、間接或衍生性損害（包括任何利益損失、資料遺失、人身傷害或財產損失），除其發生係可完全歸責於本公司所致者，本公司不負擔賠償責任。本服務之部分內容係透過協力廠商之服務所完成。用戶瞭解並同意，本公司可能會為提供本服務之目的，而將透過本服務蒐集取得之資料與該協力廠商共享。用戶同意於使用本服務過程中所有之資料記錄，均以本服務系統所儲存之電子資料為準。如遇有糾紛或訴訟，用戶同意以本服務系統所記錄之電子資料為認定標準。<br/>
            3. 本公司得修改或中止各項提供之服務內容，並於網站公告後生效(不須經過個別通知)，用戶不得因此要求任何賠償。<br/>
        </div>
        <div class="item">
            十八、條款增訂、修改及終止<br/>
            本服務協議如有增訂、修改或終止致用戶使用本服務之權益有異動者，本公司將於本服務之網站、旅電應用程式公告，如用戶有任何意見歡迎隨時與本公司反應。非經本公司事前書面同意，用戶不得將本協議所定之權利義務全部或一部轉讓予他人。本公司有權將本協議所定之權利義務全部或部分轉讓予他人，包括但不限於於：轉讓予關係企業；轉讓予本公司的股份或資產收購方；轉讓予與本公司合併之存續公司。如用戶有違反本協議之情形，而本公司未請求用戶依本協議履約者，不得視為或構成本公司放棄請求用戶依本協議履約之權利。本協議所有與本公司責任限制與排除相關之約款，或與用戶承擔風險、擔保和賠償責任相關之約款，於本協議對該用戶終止後仍然有效。且在本協議對該用戶終止時，用戶尚未支付的所有應付款項視為到期款項，用戶應於本協議經終止時向本公司給付之。<br/>
            十九、準據法及管轄法院<br/>
            1. 本協議構成用戶與本公司就用戶使用本服務之完整合意，取代用戶先前與本公司有關本服務所為之任何約定；用戶於使用本服務、關係企業服務、第三方內容或軟體時，可能亦須適用額外條款或條件。本協議之解釋與適用，以及與本協議有關的爭議，除法律另有規定者外，均應依照中華民國法律予以處理，並以臺灣臺北地方法院 為第一審管轄法院。<br/>
            2. 本公司未行使或執行本協議任何權利或規定，不構成前開權利或規定之棄權。若任何本協議規定，經有管轄權之法院認定無效，當事人仍同意法院應努力使當事人於前開規定所表達之真意生效，且本協議之其他規定仍應完全有效。

			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "Agreement",
	data() {
		return {};
	},
	methods: {},
	props: {},
	components: {},
	computed: {},
	mounted() {},
	created() {},
};
</script>

<style scoped lang="less">
.item {
	margin-top: 25px;
	line-height: 25px;
}
.f26 {
	font-size: 13px;
}
</style>
